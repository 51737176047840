import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Carousel from "nuka-carousel";
import Sticky from "../components/Sticky";
import StickyContent from "../components/StickyContent";
import BreadCrumbs from "../components/BreadCrumbs";
import Img from "gatsby-image"
import {Link} from "gatsby";
const sides = {
  top: 0, // Sticks when it scrolls past the top edge
  // bottom: 0, // Sticks when it scrolls past the bottom edge
  // left: 10, // Sticks 10px from the left edge
  // right: -20, // Sticks 20px past the right edge (useful for content that should stick only when it's fully out of the frame)
};

export const VehiclePageTemplate = ({
                                      images,
                                      title,
                                      preise,
                                      background,
                                      teaser,
                                      detail_header,
                                      komfort_header,
                                      komfort,
                                      details,
                                      location,
                                      en
                                    }) => {
  return(
    <div className="content">
      <div
          className="full-width-image-container margin-top-0" style={{
        backgroundImage: `url(${
            !!background.childImageSharp ? background.childImageSharp.fluid.src : background
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
        marginTop: 0,
        marginBottom: 0
        // alignItems:'flex-start',
      }}>
        <div className="columns" style={{flex: 1}}>
          <div className="column is-offset-1"/>
          <div className="column is-11">
            <div
                style={{
                  display: 'flex',
                  height: '150px',
                  lineHeight: '1',
                  justifyContent: 'space-around',
                  alignItems: 'left',
                  flexDirection: 'column',
                }}
            >
              <div className="column is-10">
                <h2 className="has-text-weight-bold is-size-1 has-text-white">
                  {title}
                </h2>

              </div>
              <div className="column is-9">
                <p className={'is-size-6 has-text-white'}>
                  {teaser}
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Sticky sides={sides}>
        <StickyContent title={title}>
          <div className={'columns is-vcentered is-marginless'}>
            <div className="column is-offset-1"/>
            <div className="column is-11 is-between">
              <div className="is-inline-flex">
                <button className={'button is-rounded bluebg'} style={{marginRight: 8}}>
                  <a href={en ? "/en/contact" : "/contact"} style={{color: 'white'}}>
                    {en ? 'Booking request' : 'Buchungsanfrage'}
                  </a>
                </button>
                <button className={'button is-rounded blueborder'}>
                  <Link to={en ? "/en/preise" : "/preise"} className="is-blue">
                    {en ? 'Prices' : 'Mietpreise'}
                  </Link>
                </button>
              </div>
              <p className={'is-size-6 i has-text-weight-normal is-dark-snd is-inline-flex'}
                 style={{marginLeft: 16}}
              >{title}</p>
            </div>
          </div>
        </StickyContent>
      </Sticky>
      <section className="section section--gradient" style={{paddingLeft: 8}}>
        <div className="columns">
          <div className="column is-offset-1"/>
          <div className="column is-7">
            <Carousel width={'100%'} height={"auto"} heightMode={'current'}>
              {images.map(({image, alt, name}) =>
                  <Img fluid={image.childImageSharp.fluid}  alt={alt} key={alt} objectFit="cover"/>
              )}
            </Carousel>
          </div>
          <div className="column is-4">
            <BreadCrumbs location={location}/>
          </div>
        </div>

        <div className="columns">
          <div className="column is-offset-1"/>
          <div className="column is-11">
            <h4 className="is-size-2 has-text-weight-normal is-dark-snd">
              {detail_header}
            </h4>
          </div>
        </div>

        <div className={'columns'}>
          <div className="column is-offset-1"/>
          <div className="column is-7">
            <div className="columns">
              <table className="table">
                <tbody>
                {details.map((detail, i) =>
                    <tr key={i}>
                      <td>{en ? detail.detail_value_de : detail.detail_name_de}</td>
                      <td>{detail.detail}</td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="column is-offset-4"/>
        </div>

      </section>
      <section className="section section--gradient" style={{paddingLeft: 8}}>
        <div className="columns">
          <div className="column is-offset-1"/>
          <div className="column is-11">
            <h4 className="is-size-2 has-text-weight-normal is-dark-snd">
              {komfort_header}
            </h4>
          </div>
        </div>
        {komfort.map((comfort, i) =>
            <div className="columns is-vcentered" key={i}>
              <div className={'column is-10 is-vcentered is-offset-1'}>
                <span className="icon has-text-success is-medium">
                  <i className="fas fa-check-square fa-lg"/>
                </span>
                <p className="has-text-weight-light has-text-left has-text-grey-dark is-size-6 is-raleway is-inline">
                  {en ? comfort.komfort_en : comfort.komfort_de}
                </p>
              </div>
            </div>
        )}
      </section>
    </div>
)}

VehiclePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,

  images: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        alt: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      })),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

const VehiclePage = ({data, location}) => {
  const {frontmatter} = data.markdownRemark
  // console.log("VehiclePage ", frontmatter)
  const reg = new RegExp("/en")
  const en = reg.test(location.pathname);

  return (
      <Layout location={location}>
        <VehiclePageTemplate
            location={location}
            background={frontmatter.title_image}
            images={frontmatter.images || []}
            title={frontmatter.title}
            preise={frontmatter.pricing || []}
            teaser={en ? frontmatter.teaser_en : frontmatter.teaser}
            detail_header={en ? frontmatter.detail_header_en : frontmatter.detail_header}
            details={frontmatter.details || []}
            komfort_header={en ? frontmatter.komfort_header_en : frontmatter.komfort_header}
            komfort={frontmatter.komfort || []}
            en={en}
        />
      </Layout>
  )
}

VehiclePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VehiclePage

export const pageQuery = graphql`
    query VehiclePostById($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                category
                title_image {
                    name
                    childImageSharp {
                        fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                teaser
                teaser_en
                detail_header
                detail_header_en
                details {
                    detail
                    detail_name_de
                    detail_value_de
                }
                komfort_header
                komfort_header_en
                komfort {
                    komfort_en
                    komfort_de
                }
                preise {
                    start
                    end
                    price
                    name
                }
                templateKey
                date(formatString: "MMMM DD, YYYY")
                images {
                    title
                    alt
                    image {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }

            }
        }
    }
`

