import React from "react";
import {graphql, StaticQuery} from "gatsby";
import PropTypes from "prop-types";

class BreadCrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSubGroup = (vehicles, groupName) => {
    const {location: {pathname}} = this.props;

    return vehicles.edges
        .filter(({ node:{frontmatter }}) =>
          frontmatter.category === groupName)
        .map(({node:{frontmatter, id, fields}}) =>
          <li key={id} className={`${fields.slug === pathname ? 'is-active' : ''}`}>
            <a href={fields.slug} className={fields.slug === pathname ? 'is-dark-snd' : ''}>{frontmatter.title}</a>
          </li>
      )
  }

  renderCategories = () => {
    const {categories, vehicles} = this.props.data;
    return categories.edges.map(({node: {frontmatter:{title}, id: titleID}}) =>
      <div style={{marginBottom:16}} key={titleID}>
        <li className={'breadcrumb-title is-dark-snd'}>{title}</li>
        {this.renderSubGroup(vehicles, title)}
      </div>
          )
  }

  render() {
    const {categories, vehicles} = this.props.data;
    // console.log({categories, vehicles, pathname})

    return(
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul style={{flexDirection: 'column'}}>
            {this.renderCategories()}
          </ul>
        </nav>
    )
  }
}
BreadCrumbs.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const query = graphql`
    query BreadCrumbQuery {
        vehicles: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "vehicle-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        category
                        teaser_en
                        detail_header
                        detail_header_en
                        details {
                            detail
                            detail_name_de
                            detail_value_de
                        }
                        komfort_header
                        komfort_header_en
                        komfort {
                            komfort_en
                        }
                        preise {
                            start
                            end
                            price
                            name
                        }
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        images {
                            title
                            alt
                            image {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 526, quality: 92) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        categories: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "categories" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`
export default (props) => (
    <StaticQuery
        query={query}
        render={(data) => <BreadCrumbs data={data} {...props} />}
    />
)

